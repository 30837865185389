/*breakpoints and mixin for whole application */

$media-xl: 1920px;
$media-lg: 1320px;
$media-md: 960px;
$media-sm: 600px;
$media-xs: 0px;
$media_orientation_landscape: landscape;
$media_orientation_portrait: portrait;

$spacing_unit: 8px;

/* Add to your grid or other partial */

// Media and above
@mixin media_above($media) {
  @media only screen and (min-width: $media) {
    @content;
  }
}

// Media and below
@mixin media_below($media) {
  @media only screen and (max-width: $media) {
    @content;
  }
}

// Between media_min and media_max
@mixin media_between($media_min, $media_max) {
  @media only screen and (min-width: $media_min) and (max-width: $media_max) {
    @content;
  }
}

// Between media_min and media_max
@mixin media_orientation($media_orientation) {
  @media only screen and (orientation: $media_orientation) {
    @content;
  }
}

@mixin media_below_hidden($media-sm) {
  @include media_below($media-sm) {
    display: none;
  }
}

@mixin media_below_shown($media-sm) {
  display: none;
  @include media_below($media-sm) {
    display: unset;
  }
}

@mixin below_sm() {
  @include media_below($media-sm) {
    @content;
  }
}

@mixin below_md() {
  @include media_below($media-md) {
    @content;
  }
}

@mixin below_lg() {
  @include media_below($media-lg) {
    @content;
  }
}

@mixin below_xl() {
  @include media_below($media-xl) {
    @content;
  }
}

@mixin above_sm() {
  @include media_above($media-sm) {
    @content;
  }
}

@mixin above_md() {
  @include media_above($media-md) {
    @content;
  }
}

@mixin above_lg() {
  @include media_above($media-lg) {
    @content;
  }
}

@mixin above_xl() {
  @include media_above($media-xl) {
    @content;
  }
}
