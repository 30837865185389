.button {
  position: absolute;
  right: 5px;
  height: 50px;
  background-color: #dc3430;
  color: white;
  font-weight: 600;
  top: calc(50vh - 25px);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: #353030 1px 1px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  min-width: 105px;
  transition: all 200ms;
  opacity: 0.3;
  z-index: 10;
}

.button:hover {
  opacity: 1;
}

.button_unmock {
  background-color: #6abd6a;
}
