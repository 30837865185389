@import 'styles/_variables.scss';

.item {
  display: block;
  padding-top: 0;
  padding-bottom: 0;
}
.itemLeaf {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
}

.button {
  color: #000;
  padding: 10px 8px;
  justify-content: flex-start;
  text-transform: none;
  letter-spacing: 0;
  width: 100%;
}

.buttonLeaf {
  color: #000;
  padding: 10px 8px;
  justify-content: flex-start;
  text-transform: none;
  letter-spacing: 0;
  width: 100%;
  font-weight: 400;

  .depth-0 {
    font-weight: 500;
  }
}

.icon {
  color: #000;
  display: flex;
  align-items: center;
  margin-right: $spacing_unit;
}

.expandIcon {
  margin-left: auto;
  height: 16px;
  width: 16px;
  color: #000;
}

.label {
  display: flex;
  align-items: center;
  color: #000;
  margin-left: auto;
  icon {
    color: #000;
  }
}

.active {
  background: #ffffff1c;
  border-radius: 5;
  color: '#000';
  font-weight: 500;
  icon {
    color: #000;
  }
}

.activeContainer {
  background: #ffffff1c;
  border-radius: 5px;
}
