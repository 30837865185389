@import 'styles/_variables.scss';

.root {
  z-index: 400;
  background-color: #1a3b59 !important;
}
.toolbar {
  min-height: 64px;
}
.logo {
  height: 50px;
}
