@import 'styles/_variables.scss';

.content {
  height: 100%;
  background-color: #f4f6f8;
  // padding-top: 56px;
  @include above_sm() {
    // padding-top: 64px;
  }
}
